import RecomendedArticlePages from "../pages/recommendedArticlesPage/RecomendedArticlePages";

export const ROUTES = {
    COMMON: {
      RECOMMENDED_ARTICLES: "/",
      ARTICLES: "/articles/:slug",
      TAG_ARTICLES: "/tag/:tagName/:tagId",
      CATEGORY_ARTICLES: "/category/:categoryName/:categoryId",
      NOT_FOUND: "*",
    },
  };