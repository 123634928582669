import React from 'react'
import { Route, Routes } from 'react-router-dom'
import commonRoutes from './commonRoutes'

const AppRoutes = () => {
  return (
    <Routes>
        {commonRoutes}
    </Routes>
  )
}

export default AppRoutes
