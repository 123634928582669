import React from "react";
import { Navbar, Nav, NavDropdown, Button } from "react-bootstrap";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import "./Header.css";
import { CiUser } from "react-icons/ci";
import { MdKeyboardArrowDown } from "react-icons/md";

const Header = () => {
  return (
    <Navbar bg="light" expand="lg" className="px-5 py-3 shadow-lg">
      <Navbar.Brand className="navclassbrand">
        <img
          src='../../assets/static/logo.png'
          alt="logo"
          style={{ width: "60px", height: "30px" }}
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ms-5 me-4 navclass">
          <NavDropdown
            title={
              <span>
                Advice <MdKeyboardArrowDown color="#CC1C1E" size={24} />
              </span>
            }
            id="adviceDropdown"
          >
            <NavDropdown.Item>
              <Link to="/advice/option1">Option 1</Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <Link to="/advice/option2">Option 2</Link>
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown
            title={
              <span>
                Fashion <MdKeyboardArrowDown color="#CC1C1E" size={24} />
              </span>
            }
            id="fashionDropdown"
          >
            <NavDropdown.Item>
              <Link to="/fashion/option1">Option 1</Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <Link to="/fashion/option2">Option 2</Link>
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown
            title={
              <span>
                Beauty <MdKeyboardArrowDown color="#CC1C1E" size={24} />
              </span>
            }
            id="beautyDropdown"
          >
            <NavDropdown.Item>
              <Link to="/beauty/option1">Option 1</Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <Link to="/beauty/option2">Option 2</Link>
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown
            title={
              <span>
                Accessories <MdKeyboardArrowDown color="#CC1C1E" size={24} />
              </span>
            }
            id="accessoriesDropdown"
          >
            <NavDropdown.Item>
              <Link to="/accessories/option1">Option 1</Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <Link to="/accessories/option2">Option 2</Link>
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown
            title={
              <span>
                Fitness <MdKeyboardArrowDown color="#CC1C1E" size={24} />
              </span>
            }
            id="fitnessDropdown"
          >
            <NavDropdown.Item>
              <Link to="/fitness/option1">Option 1</Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <Link to="/fitness/option2">Option 2</Link>
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown
            title={
              <span>
                Talk of Town <MdKeyboardArrowDown color="#CC1C1E" size={24} />
              </span>
            }
            id="talkOfTownDropdown"
          >
            <NavDropdown.Item>
              <Link to="/talkoftown/option1">Option 1</Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <Link to="/talkoftown/option2">Option 2</Link>
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
        <div className="d-flex flex-column flex-md-row align-items-start btngap ms-3">
          <Button
            as={Link}
            to="/your-target-path"
            className="post-audition-btn no-border  mb-md-0 "
            style={{
              backgroundColor: "#CC1C1E",
              borderRadius: "50px",
              fontWeight: "bold",
              border :'0',
              fontSize: "12px",
              padding:"10px 15px 10px 15px",
            }}
          >
            Post Audition Now
          </Button>

          <Link
            to="/login"
            style={{ textDecoration: "none", color: "inherit" }}
            className=" mt-2 mt-md-1 ml-md-3 mx-1"
          >
            <CiUser size="1em" /> Login / Register
          </Link>
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
