// src/components/Sidebar.js
import React from 'react';
import { Card} from 'react-bootstrap';
import { TbWorld } from "react-icons/tb";
import { BsFillBucketFill } from "react-icons/bs";
import './sidebar.css';
const Sidebar = () => (
  <div className='mt-4'>
    <Card className="mb-4" style={{borderRadius:"20px"}}>
      <Card.Img variant="top" src="../assets/static/sideAd1.png" />
    </Card>
    
    <span className="d-flex align-items-center text-align-center my-3 mb-4">
  <TbWorld size={20} className="me-1" color='#4B5563' />
  <h6 className="mb-0" style={{color:'#4B5563'}}>Pages to Follow</h6>
</span>

   
    <Card className="mb-3 shadowclass" style={{ borderRadius: "20px"  }}>
  <Card.Img variant="top" src="../assets/static/sideAdd2.png" style={{ borderRadius: "20px 20px 0px 0px" }} />

  
  <p className="mt-3 ms-3 mb-1" style={{ color: "#CC1C1E", fontSize: "0.8rem", fontWeight: "bold" }}>
    Movies & Web Series
  </p>

  <Card.Body className="mt-0">

    <p style={{ color: "#000000", fontWeight: "600" }}>Take My Audition</p>
    <p>Some follow suggestions here...</p>
  </Card.Body>
</Card>


    <span className="d-flex align-items-center text-align-center mt-4 mb-4">
  <BsFillBucketFill size={20} className="me-1"  color='#4B5563'/>
  <h6 className="mb-0" style={{color:'#4B5563'}}>Shop Now</h6>
</span>
    <Card className="mb-3 shadowclass" style={{borderRadius:"20px"}}>
      <Card.Img variant="top" src="../assets/static/sideAdd3.png" style={{borderRadius: "20px 20px 0px 0px"}}/>
      <Card.Body className="mt-0">

<p style={{ color: "#374151", fontWeight: "600" , fontSize:"0.8rem",
    fontWeight: 600,
    fontSize: '0.8rem',
    background: '#f3eded',
    borderRadius: '20px',
    width: '34%',
    padding: '5px', marginBottom: '10px'
    }} className='text-center'>footwear</p>
        <p className='fw-bold text-dark ms-2 mb-0'>URBANBOX</p>
        <p className='pclass'>Trending Stylish Casual Outdoor Sneakers Shoes For Men ...</p>
</Card.Body>
   

    </Card>
  </div>
);


export default Sidebar;
