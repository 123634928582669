import React, { createContext, useState, useContext, useEffect } from "react";

// Create Language Context
const LanguageContext = createContext();

// Language Provider
export const LanguageProvider = ({ children }) => {
  // const [language, setLanguage] = useState("en");

  const [langCode, setLangCode] = useState(null);
  const [hostname,setHostname] = useState(null);
  // Map domains to language codes
const domainToLangMap = {
  "takemyaudition.com": "en",
  "takemyaudition.in": "hi",
  "takemyaudition.ae": "ar",
  "takemyaudition.me": "ar",
  "localhost": "en",
};

const getLanguageFromDomain = (hostname) => {
  return domainToLangMap[hostname] ; // Default to English if not found
};

useEffect(() => {
  // Detect the language code dynamically
  const host = window.location.hostname;
  setHostname(host);
  console.log(hostname,"this is hostname");
  const detectedLangCode = getLanguageFromDomain(host);
  console.log(detectedLangCode, "this is detectedLangCode");
  setLangCode(detectedLangCode);
}, []);


  return (
    <LanguageContext.Provider value={{langCode,hostname}}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
