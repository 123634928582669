// src/components/Comments.js
import React, { useState } from 'react';
import { Button, Collapse, Container, Row, Col  } from 'react-bootstrap';
import './Comment.css'

const Comments = () => {
  const [open, setOpen] = useState(false);
  const reviews = [
    {
      title: "Very Easy to Use !",
      author: "Mike Jackson",
      date: "19 Aug 2019",
      comment:
        "Nunc vitae dolor est. Phasellus sagittis, ligula rhoncus lacinia finibus, odio ligula eleifend eros, sed pellentesque justo elit non odio.",
    },
    {
      title: "Simple and Efficient",
      author: "John Doe",
      date: "10 Sep 2020",
      comment:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent id nisl in ligula ultrices auctor.",
    },
    {
      title: "Highly Recommended",
      author: "Sarah Lee",
      date: "15 Mar 2021",
      comment:
        "Aliquam erat volutpat. Quisque vehicula lacus in dolor fermentum, a sollicitudin ligula bibendum.",
    },
    {
      title: "Great Experience",
      author: "Emma Watson",
      date: "05 Nov 2022",
      comment:
        "Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae.",
    },
  ];
  return (
    <Container className="my-4 p-0">
      <div>
        <p className='commentclass'>0 Comment</p>
      </div>

      <Row>
        <Col xs={12} md={11}>
          <div
            className="p-3 widthClass mb-3"
            style={{ backgroundColor: "#F1F1F1", borderRadius: "13px" }}
          >
            <p className="lead marginclass m-0 text-center" style={{ fontSize: "1rem", fontWeight: "500", color: "#6C6C6C" }}>No Comments Yet!
            </p>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={11}>
          <div
            className="p-4 widthClass text-center mb-4"
            style={{ backgroundColor: "#FFE0E1", borderRadius: "8px" }}
          >
            <button
              className="badge bg-danger text-white me-2 mb-2 badgeclass border-0"
            >
              Sign in / Sign up
            </button>
            <p className="lead marginclass m-0 text-center" style={{ fontSize: "1rem", fontWeight: "500", color: "#6C6C6C" }}>
              to join this conversation on TakeMyAudition.
            </p>
          </div>
        </Col>
      </Row>

      <div>
        <p className='commentclass text-dark fw-bold'>20 Comments</p>
      </div>

      <div className='mb-4'>
      {reviews.map((review, index) => (
        <Row key={index}>
          <Col xs={12} md={11} className="colclass">
            <div
              className="p-4 mb-3"
              style={{ backgroundColor: "#F1F1F1", borderRadius: "13px" }}
            >
              <Row className="mb-2">
                <Col
                  className="lead marginclass m-0"
                  style={{ fontSize: "1rem", fontWeight: "bold" }}
                >
                  {review.title}
                </Col>
                <Col className="text-danger fw-bold text-end">REPLY</Col>
              </Row>

              <Row className="mb-2">
                <Col
                  className="lead m-0"
                  xs={12}
                  md={2}
                  style={{
                    fontSize: "0.8rem",
                    fontWeight: "bold",
                    color: "#6C6C6C",
                  }}
                >
                  by {review.author}
                </Col>
                <Col
                  className="fw-bold"
                  xs={12}
                  md={2}
                  style={{ fontSize: "0.8rem", color: "#6C6C6C" }}
                >
                  on {review.date}
                </Col>
              </Row>

              <Row>
                <Col
                  className="lead m-0"
                  style={{ fontSize: "0.8rem", fontWeight: "500" }}
                >
                  {review.comment}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      ))}
    </div>

<div className='mb-2'>
  <p className='text-danger fw-bold '> Add new Comments</p>
  <Col className='colclass'>
  <textarea style={{borderRadius:"10px"}}
    className="form-control"
    id="exampleFormControlTextarea1"
    placeholder='Enter The Description Here...'
    rows="4"
  ></textarea>
  </Col>
</div>
<div className='mb-3 colclass text-end'>
  <Button  style={{
              backgroundColor: "#CC1C1E",
              borderRadius: "50px",
              fontWeight: "500",
              border :'0',
              fontSize: "13px",
              padding: "10px ",
            }} className='btnclasss'>SUBMIT</Button>
</div>
    </Container>
  );
};

export default Comments;
