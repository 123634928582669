import React from 'react';
import { Container, Row, Col, Card , Button} from 'react-bootstrap';
import { Link } from 'react-router-dom';
const RecommendedArticles = ({articles}) => {
  // const products = [
  //   {
  //     id: 1,
  //     image: "../assets/static/sideAdd3.png",
  //     category: "Movies & Web Series",
  //     title: "URBANBOX",
  //     description: "Trending Stylish Casual Outdoor Sneakers Shoes For Men ..."
  //   },
  //   {
  //     id: 2,
  //     image: "../assets/static/sideAdd3.png",
  //     category: "Movies & Web Series",
  //     title: "URBANBOX",
  //     description: "Trending Stylish Casual Outdoor Sneakers Shoes For Men ..."
  //   },
  //   {
  //     id: 3,
  //     image: "../assets/static/sideAdd3.png",
  //     category: "Movies & Web Series",
  //     title: "URBANBOX",
  //     description: "Trending Stylish Casual Outdoor Sneakers Shoes For Men ..."
  //   }
  // ];

  return (
    <Container className="my-3">
       <div className="articleclass justify-content-between align-items-center mb-3 colclass">
        <div>
          <h3 className="fw-bold">Recommended Articles</h3>
        
        </div>
        <Link to='/recommendedArticles'>
        <Button className="btn-md fw-bold bgclass" style={{borderRadius:"50px" , padding:"15px",fontSize:"0.8rem"}}>
          View All
        </Button>
        </Link>
      </div>
      <Row className="colclass">
        {articles?.relatedArticles?.map((product) => (
          <Col key={product.id} sm={6} md={5} lg={4} className="mb-3">
            <Card className="mb-3 shadowclass" style={{ borderRadius: "20px" }}>
              <Card.Img
                variant="top"
                src={product?.banner?.bannerImage}
                style={{ borderRadius: "20px 20px 0px 0px" }}
              />
              <Card.Body className="mt-0">
                <p
                  style={{
                    color: "#CC1C1E",
                    fontWeight: "600",
                    fontSize: "0.8rem",
                   
                    marginBottom: "10px"
                  }}
                  className="text-start ms-2"
                >
                  {product?.category.title}
                </p>
                <p className="fw-bold text-dark ms-2 mb-0">{product.title}</p>
                <p className="pclass">{product.shortDescription}</p>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default RecommendedArticles;
