import React from "react";
import { Route } from "react-router-dom";
import { ROUTES } from "./constans";
import MainComponent from "../pages/mainComponent/MainComponent";
import RecommendedArticlesPages from "../pages/recommendedArticlesPage/RecomendedArticlePages";

/* Common Pages */


const commonRoutes = [
  <Route key="article" path={ROUTES.COMMON.ARTICLES} element={<MainComponent/>} />,
  <Route key="recommendedArticles" path={ROUTES.COMMON.RECOMMENDED_ARTICLES} element={<RecommendedArticlesPages/>} />
  ,
  <Route key="tagArticles" path={ROUTES.COMMON.TAG_ARTICLES} element={<RecommendedArticlesPages/>} />,
  <Route key="categoryArticles" path={ROUTES.COMMON.CATEGORY_ARTICLES} element={<RecommendedArticlesPages/>} />
];

export default commonRoutes;