// src/components/AdBanner.js
import React from 'react';
import { Container, Image } from 'react-bootstrap';
import {  MdKeyboardArrowLeft } from "react-icons/md";
import './adBanner.css';
const AdBanner = ({articles}) => {
console.log("articles from ad banneer",articles);
return(
  <Container className=" mx-0 p-3" style={{ width: '100%' }} fluid>
      <span className="d-flex align-items-center banrmarginclass mb-2"><MdKeyboardArrowLeft color="#CC1C1E" size={20} />All Articles</span>
    <Image src={articles?.banner?.bannerImage} alt="Advertisement" className='img-fluid imagclassssss'/>
  </Container>
);
};

export default AdBanner;
