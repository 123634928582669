import React, { useEffect, useState } from 'react';
import CommonLayout from '../../layout/commonlayout/commonlayout';
import { Container, Row, Col, Spinner , Button } from 'react-bootstrap';
import Sidebar from '../sidebar/Sidebar';
import { MdArticle } from "react-icons/md";
import RecArcBanner from '../recArticleLayout/recArcBanner/RecArcBanner';
import RecSidebar from '../recArticleLayout/recSidebar/RecSidebar';
import { MdLanguage } from "react-icons/md";
import { useParams } from 'react-router-dom';
import { fetchArticleByCategory, fetchArticleByTag, fetchArticleBySubCategory, fetchAllArticlesByLangCode } from '../../services/apiServices';
import { useLanguage } from '../../component/languageContext/LanguageContext';
import './RecomArticlPage.css';
const RecomendedArticlePages = () => {
  const { langCode } = useLanguage();
  const [articlesData, setArticlesData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); 
  const [totalPages, setTotalPages] = useState(0);// Track current page
  const { tagName, tagId, categoryName, categoryId } = useParams();
  const [loading, setLoading] = useState(false);
  const fetchArticlesData = async () => {
    setLoading(true);
    try {
      let response;
      if (tagName && tagId) {
        response = await fetchArticleByTag(langCode, tagId, currentPage);
      } else {
        response = await fetchAllArticlesByLangCode(langCode, currentPage);
      }

      if (response) {
        setArticlesData((prevArticles) => [...prevArticles, ...response.data.articles]);
        setTotalPages(response.data.totalPage); 
      }
    } catch (error) {
      console.error("Error fetching articles:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (langCode) {
      fetchArticlesData();
    }
  }, [langCode, currentPage]);

  const loadMoreArticles = () => {
    if (currentPage < totalPages) {
      setLoading(true);
      setTimeout(() => {
        setCurrentPage((prevPage) => prevPage + 1);
        setLoading(false);
      }, 500); 
    }
  };
  return (
    <div>
      <CommonLayout>
        <Container fluid>
          <Row className='classContainer'>
            <Col xs={12} md={8}>
              <div className='latstclass'>
                <h5 className='fw-bold d-flex align-items-center'><MdArticle /> Latest Articles</h5>
                <hr></hr>
              </div>
            </Col>
            <Col xs={12} md={4} className='secondclass'>
              <div className='mt-5 mb-1 ms-5'>
                <h5 className='fw-bold d-flex align-items-center'><MdLanguage /> Pages to follow</h5>
                <hr></hr>
              </div>
            </Col>
          </Row>

          {loading && currentPage === 1 ? (
            <div className="text-center my-4">
              <Spinner animation="border" role="status" variant="danger">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <>
              <Row className="mx-2">
                <Col lg={8} className="order-lg-1 order-1  banerclass">
                  <div className='mainclass'>
                    <RecArcBanner articlesData={articlesData} />
                  </div>
                </Col>
                <Col lg={3} className="order-lg-2 order-2 recsideclass ">
                  <RecSidebar />
                </Col>
              </Row>
              {currentPage < totalPages && (
                <div className="text-center my-4">
                  <Button
    onClick={loadMoreArticles}
    disabled={loading} 
    className={`load-more-btn ${loading ? 'loading' : ''}`}
            style={{
              backgroundColor: "#CC1C1E",
              borderRadius: "50px",
              fontWeight: "bold",
              border :'0',
              fontSize: "17px",
              padding:"10px 15px 10px 15px",
            }}
  >
    {loading ? (
      <Spinner animation="border" size="sm" />
    ) : (
      "Load More"
    )}
  </Button>
                </div>
              )}
            </>
          )}
        </Container>
      </CommonLayout>
    </div>
  );
};

export default RecomendedArticlePages;










  // const fetchAllArticles = async () => {
  //   try {
  //     const response = await fetchAllArticlesByLangCode(langCode, currentPage);
  //     if (response) {
  //       // Append new articles to existing ones
  //       setArticlesData(prevArticles => [...prevArticles, ...response.data.articles]);
        
  //       // Check if we have more articles to load
  //       if (response.data.articles.length < 10) {
  //         setHasMore(false); // No more articles
  //       } else {
  //         setCurrentPage(response.totalPage); // Update current page
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error fetching articles:", error);
  //   }
  // }