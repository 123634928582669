import './App.css';
import LanguageModal from './component/modal/LanguageModal';
import React, { useState, useEffect } from "react";
import MainComponent from './pages/mainComponent/MainComponent';
import { FaGlobe } from "react-icons/fa";
import AppRoutes from './routes/AppRoutes';
import { Routes, Route } from 'react-router-dom'
import RecommendedArticles from './pages/recommendedArticle/RecommendedArticle';
function App() {
  return (
    <div className='App'>
      
        <AppRoutes/>
        {/* <Route path="/recommendedArticles" element={<RecommendedArticles />} /> */}
      {/* <button className="floating-btn" onClick={handleOpenModal}>
      <FaGlobe className="icon" />
      </button> */}

      {/* <LanguageModal showModal={showModal} onClose={handleCloseModal} /> */}
    </div>
  );
}

export default App;
