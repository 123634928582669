// import React from "react";
// import { Container, Row, Col, Button, Image } from "react-bootstrap";
// import {
//   FaTwitter,
//   FaFacebook,
//   FaInstagram,
//   FaYoutube,
//   FaApple,
// } from "react-icons/fa";

// const Footer = () => {
//   return (
//     <footer>
//       {/* Top Section */}
//       <div
//         className="text-white text-center py-4"
//         style={{ backgroundColor: "#CC1C1E" }}
//       >
//         <p style={{ fontSize: "1.5rem", margin: "0.2rem 0" }}>
//           {" "}
//           {/* Adjusted margin */}
//           Looking for the <strong>Best Talent</strong> for your new project?
//         </p>
//         <p style={{ fontSize: "1.5rem", margin: "0.2rem 0" }}>
//           {" "}
//           {/* Adjusted margin */}
//           Find them on <strong>TakeMyAudition</strong>
//         </p>
//         <Button
//           variant="light"
//           className="mt-2"
//           style={{ borderRadius: "25px" }}
//           size="md"
//         >
//           Post Your Audition Now
//         </Button>
//       </div>

//       {/* Main Footer Section */}
//       <Container
//         fluid
//         className="text-white py-4 px-5"
//         style={{
//           backgroundColor: "#000000",
//         }}
//       >
//         <Row className="text-center text-md-left  mx-md-4 ">
//           {/* Logo Section */}
//           <Col md={3} className="mb-4 mb-md-0" style={{ textAlign: "left" }}>
//             <img
//               src="../assets/static/footerlogo.png"
//               alt="logo"
//               style={{ width: "80px", height: "40px" }}
//             />
//             <p className="my-2">Finding good looking talent</p>
//             <div className="d-flex flex-md-row flex-md-column align-items-start" width="100%">
//               <Button
//                 className=" my-md-2"
//                 style={{
//                   backgroundColor: "#000000",
//                   border: "1px solid #656565",
//                   fontSize: "0.5rem",
//                   height: "50px",
//                   borderSizing: "border-box",
//                 }}
//               >
//                 <FaApple size={20} />
//                 <span>
//                   Download on the <h6>Apple Store</h6>
//                 </span>
//               </Button>

//               <Button
//               className="ms-1 ms-md-0 "
//                 style={{
//                   backgroundColor: "#000000",
//                   border: "1px solid #656565",
//                   fontSize: "0.5rem",
//                   height: "50px",
//                   borderSizing: "border-box",
//                   padding:"0 10px"
//                 }}
//               >
//                 <img
//                   src={`${process.env.PUBLIC_URL}/assets/static/google_play.svg`}
//                   alt="Google Play"
//                   width={20}
//                 />
//                 <span>
//                   Get it on <h6>Google Play</h6>
//                 </span>
//               </Button>
//             </div>
//           </Col>

//           {/* Links Section */}
//           <Col md={2} className="mb-4 mb-md-0" style={{ textAlign: "left" }}>
//             <h5>Links</h5>
//             <ul className="list-unstyled">
//               <li>
//                 <a className="text-white text-decoration-none">Feedback</a>
//               </li>
//               <li>
//                 <a className="text-white text-decoration-none">Disclaimer</a>
//               </li>
//               <li>
//                 <a className="text-white text-decoration-none">
//                   Advertise with us
//                 </a>
//               </li>
//               <li>
//                 <a className="text-white text-decoration-none">Contact Us</a>
//               </li>
//               <li>
//                 <a className="text-white text-decoration-none">
//                   Privacy Policy
//                 </a>
//               </li>
//             </ul>
//           </Col>

//           {/* Company Information Section */}
//           <Col md={3} className="mb-4 mb-md-0" style={{ textAlign: "left" }}>
//             <h5>Company Information</h5>
//             <p>TakeMyAudition Lounge, New Delhi</p>
//             <p>Email: info@takemyaudition.com</p>
//             <p>Phone: 8800248919</p>
//             <p>WhatsApp: 8800248919</p>
//           </Col>

//           {/* Social & Register Section */}
//           <Col
//             md={3}
//             className="text-center text-md-left"
//             style={{ textAlign: "left" }}
//           >
//             <h5>Connect with Us</h5>
//             <div className="mb-3">
//               <div className="d-flex justify-content-center mb-3">
//                 <a className="text-white me-3">
//                   <FaTwitter size={24} />
//                 </a>
//                 <a className="text-white me-3">
//                   <FaFacebook size={24} />
//                 </a>
//                 <a className="text-white me-3">
//                   <FaInstagram size={24} />
//                 </a>
//                 <a className="text-white">
//                   <FaYoutube size={24} />
//                 </a>
//               </div>
//               <div className="d-flex flex-column align-items-center">
//                 <Button className="mb-2" style={{ backgroundColor: "#CC1C1E" }}>
//                   Register as Artist
//                 </Button>
//                 <Button style={{ backgroundColor: "#CC1C1E" }}>
//                   Register as Agency
//                 </Button>
//               </div>
//             </div>
//           </Col>
//         </Row>
//       </Container>

//       {/* Bottom Footer Section */}
//       <div
//         className=" text-center text-white py-3 "
//         style={{ backgroundColor: "#000000", borderTop: "1px solid #656565" }}
//       >
//         <p className="mb-0">&copy; 2023 Copyright TakeMyAudition</p>
//       </div>
//     </footer>
//   );
// };

// export default Footer;

import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  FaTwitter,
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaApple,
} from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import "./footer.css";

const Footer = () => {
  return (
    <footer>
      {/* Top Section */}
      <div
        className="text-white text-center py-4"
        style={{ backgroundColor: "#CC1C1E" }}
      >
        <p className="firstfootclass mb-0">
          Looking for the <strong>Best Talent</strong> for your new project?
        </p>
        <p className="firstfootclass">
          Find them on <strong>TakeMyAudition</strong>
        </p>
        <Button
          variant="light"
          className="mt-2 mb-3 py-2"
          style={{ borderRadius: "25px" }}
          size="md"
        >
          <Link
            to="/post-audition"
            className="text-decoration-none text-dark fw-bold p-2"
          >
            Post Your Audition Now
          </Link>
        </Button>
      </div>

      {/* Main Footer Section */}
      <Container
        fluid
        className="text-white py-4 px-5"
        style={{
          backgroundColor: "#000000",
        }}
      >
        <Row className="text-center text-md-left  mx-md-4 ">
          {/* Logo Section */}
          <Col md={4} className="mb-4 mb-md-0" style={{ textAlign: "left" }}>
            <div className="d-flex flex-md-row flex-md-column align-items-start my-3 mb-2">
              <img
                src="../assets/static/footerlogo.png"
                alt="logo"
                style={{ width: "100px", height: "auto", marginBottom: "15px" }}
              />
              <p className="my-2 ms-4 ms-md-0">Finding good looking talent</p>
            </div>

            <div
              className="d-flex flex-md-row flex-md-column align-items-start"
              width="100%"
            >
              <Button
                className="my-md-2 d-flex align-items-center"
                style={{
                  backgroundColor: "#000000",
                  border: "1px solid #656565",
                  fontSize: "0.8rem",
                  height: "50px",
                  padding: "0 10px",
                  borderSizing: "border-box",
                }}
              >
                <FaApple size={30} className="" />
                <span className="text-start">
                  <Link
                    to="/download/apple"
                    className="text-decoration-none text-white"
                    style={{ display: "block", lineHeight: "1.2" }}
                  >
                    <div style={{ fontSize: "10px" }}>Download on the</div>
                    <div style={{ fontSize: "0.9rem", fontWeight: "bold" }}>
                      Apple Store
                    </div>
                  </Link>
                </span>
              </Button>

              <Button
             className="my-md-2 d-flex align-items-center"
                style={{
                  backgroundColor: "#000000",
                  border: "1px solid #656565",
                  fontSize: "0.5rem",
                  height: "50px",
                  borderSizing: "border-box",
                  padding: "0 10px",
                }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/static/google_play.svg`}
                  alt="Google Play"
                  width={30}
                />
                <span className="text-start">
                  <Link
                    to="/download/google"
                    className="text-decoration-none text-white"
                    style={{ display: "block", lineHeight: "1.2" }}
                  >
                    <div style={{ fontSize: "10px" }}> Get it on</div>
                    <div style={{ fontSize: "0.9rem", fontWeight: "bold" }}>
                      Google Play
                    </div>
                  </Link>
                </span>
              </Button>
            </div>
          </Col>

          {/* Links Section */}
          <Col md={2} className="mb-4 mb-md-0" style={{ textAlign: "left" }}>
           
            <ul className="list-unstyled lisststylw">
              <li>
                <Link
                  to="/feedback"
                  className="text-white text-decoration-none"
                >
                  Feedback
                </Link>
              </li>
              <li>
                <Link
                  to="/disclaimer"
                  className="text-white text-decoration-none"
                >
                  Disclaimer
                </Link>
              </li>
              <li>
                <Link
                  to="/advertise"
                  className="text-white text-decoration-none"
                >
                  Advertise with us
                </Link>
              </li>
              <li>
                <Link to="/contact" className="text-white text-decoration-none">
                  Contact Us
                </Link>
              </li>
              <li>
                <Link
                  to="/privacy-policy"
                  className="text-white text-decoration-none"
                >
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </Col>

          {/* Company Information Section */}
          <Col md={3} className="mb-4 mb-md-0 mt-2" style={{ textAlign: "left" }}>
            <h5 className="cominfo fw-bold margincol">Company Information</h5>
            <p className="fsssssssssssss">TakeMyAudition Lounge,<br/> New Delhi</p>
            <p><MdEmail size={20}/><span className="fsssssssssssss ms-1"> info@takemyaudition.com</span></p>
            <p><FaPhoneAlt size={20} /> <span className="fsssssssssssss ms-1"> 8800248919</span></p>
            <p><FaWhatsapp size={20} /><span className="fsssssssssssss ms-1"> 8800248919</span></p>
          </Col>

          {/* Social & Register Section */}
          <Col
            md={3}
            className="text-center text-md-left mt-2"
            style={{ textAlign: "left" }}
          >
            <h5 className="cominfo fw-bold ">Connect with Us</h5>
            <div className="mb-3">
              <div className="d-flex justify-content-center mb-4">
                <Link to="/social/twitter" className="text-white me-3">
                  <FaTwitter size={24} />
                </Link>
                <Link to="/social/facebook" className="text-white me-3">
                  <FaFacebook size={24} />
                </Link>
                <Link to="/social/instagram" className="text-white me-3">
                  <FaInstagram size={24} />
                </Link>
                <Link to="/social/youtube" className="text-white">
                  <FaYoutube size={24} />
                </Link>
              </div>
              <div className="d-flex flex-column align-items-center">
                <Button
                  as={Link}
                  to="/register/artist"
                  className="text-decoration-none mb-2"
                  style={{
                    backgroundColor: "#CC1C1E",
                    border: "none",
                    borderRadius: "50px",
                    width: "65%",
                    padding:'8px'
                  }}
                >
                  Register as Artist
                </Button>

                <Button
                  as={Link}
                  to="/register/agency"
                  className="text-decoration-none "
                  style={{
                    backgroundColor: "#CC1C1E",
                    border: "none",
                    borderRadius: "50px",
                    width: "65%",
                    padding:'8px',
                    marginTop:'4px'
                  }}
                >
                  Register as Agency
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      {/* Bottom Footer Section */}
      <div
        className=" text-center text-white py-3 "
        style={{ backgroundColor: "#000000", borderTop: "1px solid #656565" }}
      >
        <p className="mb-0">&copy; 2023 Copyright TakeMyAudition</p>
      </div>
    </footer>
  );
};

export default Footer;
