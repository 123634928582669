import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, Zoom } from 'react-toastify';
import { LanguageProvider } from './component/languageContext/LanguageContext';
import LanguageModal from './component/modal/LanguageModal';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
    <LanguageProvider>
      <LanguageModal/>
    <App />
    </LanguageProvider>
    <ToastContainer
        stacked
        closeOnClick
        position="top-center"
        autoClose="2000"
        theme="colored"
        transition={Zoom}
        hideProgressBar={true}
        newestOnTop={false}
        rtl={false}
        draggable
        pauseOnHover
      />
    </BrowserRouter>

);

reportWebVitals();
