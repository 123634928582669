import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useLanguage } from "./../languageContext/LanguageContext";

const LanguageModal = ({ showModal, onClose }) => {
  const { changeLanguage } = useLanguage();

  const handleSelectLanguage = (lang) => {
    changeLanguage(lang); // Set language in context
    localStorage.setItem("selectedLanguage", lang); // Save the selection in localStorage
    onClose(); // Close modal
  };

  return (
    
    <Modal
    show={showModal}
    onHide={onClose}
    backdrop="static"
    keyboard={false}
    centered
    // style={{
    //   background: "linear-gradient(to bottom right, #f7f9fc, #eef2f7)"
    // }}
  >
    <Modal.Header
      closeButton
      className="border-0"
      style={{ backgroundColor: "#f8f9fa", borderRadius: "10px 10px 0 0" }}
    >
      <Modal.Title className="text-center w-100">
        🌐 Select Your Language
      </Modal.Title>
    </Modal.Header>
    <Modal.Body
      className="text-center"
      style={{
        // backgroundColor: "#ffffff",
        borderRadius: "0 0 10px 10px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        padding: "2rem"
      }}
    >
      <p className="mb-4 text-muted">
        Choosing the right language helps us enhance your experience!
      </p>
      <div className="d-flex justify-content-center gap-4">
        <Button
          variant="danger"
          className="px-4 py-2 rounded-pill shadow-sm fw-bold"
          style={{
            backgroundColor: "",
            border: "none",
            fontSize: "1rem",
            transition: "transform 0.2s ease, background-color 0.2s ease",
            color: "white"
          }}
          onClick={() => handleSelectLanguage("en")}
          onMouseEnter={(e) => (e.target.style.backgroundColor = "#0056b3")}
          onMouseLeave={(e) => (e.target.style.backgroundColor = "#007bff")}
        >
          🌍 English
        </Button>
        <Button
          variant="secondary"
          className="px-4 py-2 rounded-pill shadow-sm fw-bold"
          style={{
            backgroundColor: "#6c757d",
            border: "none",
            fontSize: "1rem",
            transition: "transform 0.2s ease, background-color 0.2s ease",
            color: "white"
          }}
          onClick={() => handleSelectLanguage("secondary")}
          onMouseEnter={(e) => (e.target.style.backgroundColor = "#5a6268")}
          onMouseLeave={(e) => (e.target.style.backgroundColor = "#6c757d")}
        >
          🌐 Secondary
        </Button>
      </div>
      <p className="text-muted mt-4" style={{ fontSize: "0.85rem" }}>
        You can always switch the language later in the settings.
      </p>
    </Modal.Body>
  </Modal>
  );
};

export default LanguageModal;
