import React, { useContext, useEffect, useState } from "react";
import CommonLayout from "../../layout/commonlayout/commonlayout";
import { Container, Col, Row, Spinner } from "react-bootstrap";
import MainContent from "../mainContent/MainContent";
import Comments from "../comments/Comments";
import Sidebar from "../sidebar/Sidebar";
import RecommendedProducts from "../recommendedProducts/RecommendedProducts";
import AdBanner from "../adBanner/AdBanner";
import { useLanguage } from "../../component/languageContext/LanguageContext";
import { fetchArticles } from "../../services/apiServices";
import { toast } from "react-toastify";
import RecommendedArticles from "../recommendedArticle/RecommendedArticle";
import { useParams } from "react-router-dom";

import MetaTags from "../../seo/seoConfig";
const MainComponent = () => {
  const { slug } = useParams();
const {langCode} = useLanguage();
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [langCode, setLangCode] = useState("en");

  console.log(langCode, "this is lang code");
  console.log(slug, "this is slug");
  const getArticle = async (title, code) => {
    console.log(title, "this is title inside maincomponent ================================");
    setLoading(true);
    try {
      const response = await fetchArticles(code, slug);

      if (response && response.status === 200 && response.success) {
        // toast.success(response.message);
        console.log(response.data[0], "this is data rom main component");
        setArticles(response.data[0]);
      } else {
        toast.warning(response.message || "Unexpected response format.");
      }
    } catch (error) {
      console.error("Error fetching articles:", error);
      toast.error(
        error.message ||
          "An error occurred while fetching articles. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  const createSlug = (title) => {
    return title
      .toLowerCase()
      .replace(/\s+/g," ")
      .replace(/[^\w-]+/g," ");
  };

  useEffect(() => {
    const title = createSlug(slug);
    console.log(title, "this is title");
    if (title ) {
      getArticle(title, langCode);
    }
  }, [slug]);

  const { seo, title } = articles;
  const metaKeywords = seo?.metaKeywords || "default keywords";
  const metaDescription = seo?.metaDescription || "default description";

  return (
    <CommonLayout>
      {/* for meta tags  */}
      <MetaTags
        title={title || "Default Title"}
        metaDescription={metaDescription}
        metaKeywords={metaKeywords}
      />

      <Container fluid className="p-0">
        <AdBanner articles={articles} />{" "}
        {loading ? ( // Show spinner while loading
          <div className="text-center my-4">
            <Spinner animation="border" role="status" variant="danger">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <Row className="mx-2 p-3">
            <Col lg={9} className="order-lg-1 order-1">
              <MainContent articles={articles} />
              <Comments articles={articles} />
              <RecommendedProducts articles={articles} />
              <RecommendedArticles articles={articles} />
            </Col>
            <Col lg={3} className="order-lg-2 order-2">
              <Sidebar />
            </Col>
          </Row>
        )}
      </Container>
    </CommonLayout>
  );
};

export default MainComponent;
