import { handleApiError } from "../utils/authHelper";
import { makeJsonApiRequest } from "./apiRequest";
import { toast } from "react-toastify";

const baseUrl = process.env.REACT_APP_API_URL;

export const fetchArticles = async (langCode,slug) =>{
    try {
        const {status, data} = await makeJsonApiRequest(
            "GET",
            `${baseUrl}/article/${langCode}/${slug}`,
            {}
        )
        if(status === 200 && data.success){
            console.log(data,"this is dta from api");
            return data;
        }
        else{
            toast.error(data.message);
        }
    } catch (error) {
        handleApiError(error);
    }
}


export const fetchArticleByTag = async (code,_id,currentPage) =>{
    try {
        const {status, data} = await makeJsonApiRequest(
            "GET",
            `${baseUrl}/show-article-by-tag?langCode=${code}&tagId=${_id}&page=${currentPage}`,
        )
        if(status === 200 && data.success){
            console.log(data,"this is dta from api");
            return data;
        }
        else{
            toast.error(data.message);
        }
    }catch (error) {
        handleApiError(error);
    }
} 


export const fetchArticleByCategory = async (langCode,categoryId) =>{
    try {
        const {status, data} = await makeJsonApiRequest(
            "GET",
            `${baseUrl}/article?langCode=${langCode}&categoryId=${categoryId}`,
        )
        if(status === 200 && data.success){
            console.log(data,"this is dta from api");
            return data;
        }
        else{
            toast.error(data.message);
        }
    }catch (error) {
        handleApiError(error);
    }
} 


export const fetchArticleBySubCategory = async (langCode,subCategoryId) =>{
    try {
        const {status, data} = await makeJsonApiRequest(
            "GET",
            `${baseUrl}/article?langCode=${langCode}&subCategoryId=${subCategoryId}`,
        )
        if(status === 200 && data.success){
            console.log(data,"this is dta from api");
            return data;
        }
        else{
            toast.error(data.message);
        }
    }catch (error) {
        handleApiError(error);
    }
} 



export const fetchAllArticlesByLangCode = async (code , page) =>{
    try {
        console.log(`${baseUrl}/show-articles?langCode=${code}`,"this is url");
        const {status, data} = await makeJsonApiRequest(
            "GET",
            `${baseUrl}/show-articles?langCode=${code}&page=${page}`,
        )
        if(status === 200 && data.success){
            console.log(data,"this is dta from api");
            return data;
        }
        else{
            toast.error(data.message);
        }
    }catch (error) {
        handleApiError(error);
    }
} 