import React from 'react';
import { Container, Image, Row, Col, Button } from 'react-bootstrap';
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { FaPlus } from "react-icons/fa6";
import './reacArcBanner.css';
import { Link } from 'react-router-dom';
import { useLanguage } from '../../../component/languageContext/LanguageContext';


const RecArcBanner = ({articlesData}) => {


  // const imageUrl = articlesData.bannerData[0]?.bannerImage ? articlesData.bannerData[0]?.bannerImage : articlesData.banner.bannerImage;
  console.log(articlesData,"this is article data");
  const {langCode} = useLanguage();
  return (

    <Container className=" mb-5 recbanclass p-0" style={{ width: '100%' }} fluid>
    {articlesData.map((article, index) => {
      // Check if `bannerData` or `banner` exists safely
      const bannerDataImage =
        article.bannerData && article.bannerData[0]?.bannerImage;
      const bannerImage = article.banner?.bannerImage;

      // Use `bannerDataImage` if available; fallback to `bannerImage`
      const imageUrl = bannerDataImage || bannerImage || null;

      return (
        <div key={index} className="mb-4">
          {imageUrl && (
            <div
              className="background-image mb-3"
              style={{
                backgroundImage: `url(${imageUrl})`,
                height: "200px",
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: "10px",
              }}
            />
          )}
          <Row>
            <Col>
              <span className="text-danger fw-bold d-flex align-items-center g-10 mb-2">
                <FaPlus />{" "}
                {article?.category?.name?.[langCode] ||
                  article?.category?.name?.secondary}
              </span>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={9}>
              <h5 className="sizeclass mb-2 fw-bold">{article?.title}</h5>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <p>{article?.shortDescription}</p>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} className="d-flex justify-content-end mb-3">
              <Link
                to={`/articles/${article?.title}`}
                style={{ textDecoration: "none" }}
              >
                <Button
                  className="post-audition-btn no-border mb-md-0"
                  style={{
                    backgroundColor: "#CC1C1E",
                    borderRadius: "50px",
                    border: "0",
                    fontSize: "13px",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    padding: "8px 18px",
                  }}
                >
                  Read More <HiOutlineArrowNarrowRight size={25} />
                </Button>
              </Link>
            </Col>
          </Row>
        </div>
      );
    })}
  </Container>
  );
};

export default RecArcBanner;
