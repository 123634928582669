// src/components/AdBanner.js
import React from 'react';
import { Container, Image } from 'react-bootstrap';

const MidContent = ({articles}) => (
  <Container className="my-3 mx-0 p-0"  fluid>
    <Image src={articles?.subCategory?.subCategoryImage} alt="Advertisement" className='img-fluid'/>
  </Container>
);

export default MidContent;
