import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import './Recommenrdproduct.css';
const RecommendedProducts = () => {
  const products = [
    {
      id: 1,
      image: "../assets/static/sideAdd3.png",
      category: "footwear",
      title: "URBANBOX",
      description: "Trending Stylish Casual Outdoor Sneakers Shoes For Men ..."
    },
    {
      id: 2,
      image: "../assets/static/sideAdd3.png",
      category: "footwear",
      title: "URBANBOX",
      description: "Trending Stylish Casual Outdoor Sneakers Shoes For Men ..."
    },
    {
      id: 3,
      image: "../assets/static/sideAdd3.png",
      category: "footwear",
      title: "URBANBOX",
      description: "Trending Stylish Casual Outdoor Sneakers Shoes For Men ..."
    }
  ];

  return (
    <Container className="my-3">
      <div className="articleclass justify-content-between align-items-center mb-3 colclass">
        <div>
          <h3 className="fw-bold">Recommended Products</h3>
          <p>(we might earn commissions from qualifying purchases)</p>
        </div>
        <Button className="btn-md fw-bold bgclass" style={{borderRadius:"50px" , padding:"15px",fontSize:"0.8rem"}}>
          View All
        </Button>
      </div>
      <Row className="colclass">
        {products.map((product) => (
          <Col key={product.id} sm={6} md={5} lg={4} className="mb-3">
            <Card className="mb-3 shadowclass" style={{ borderRadius: "20px" }}>
              <Card.Img
                variant="top"
                src={product.image}
                style={{ borderRadius: "20px 20px 0px 0px" }}
              />
              <Card.Body className="mt-0">
                <p
                  style={{
                    color: "#374151",
                    fontWeight: "600",
                    fontSize: "0.8rem",
                    background: "#f3eded",
                    borderRadius: "20px",
                    width: "34%",
                    padding: "5px",
                    marginBottom: "10px"
                  }}
                  className="text-center"
                >
                  {product.category}
                </p>
                <p className="fw-bold text-dark ms-2 mb-0">{product.title}</p>
                <p className="pclass">{product.description}</p>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default RecommendedProducts;
